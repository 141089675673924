'use client'

import { Footer as SDSFooter } from '@sikt/sds-footer'
import { useTranslations } from 'next-intl'

import { Flex } from '../Flex/Flex'
import { Link } from '../Link'

export function Footer() {
  const t = useTranslations('components.Footer')
  return (
    <SDSFooter>
      <Flex direction="column" alignItems="flex-end" flexGrow={1}>
        <Link href="/om/endringslogg">{t('changeLog')}</Link>
      </Flex>
    </SDSFooter>
  )
}
