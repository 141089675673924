'use client'

import { useEffect, useState } from 'react'

import { MoonStars, Sun } from '@phosphor-icons/react'
import { ToggleSwitch } from '@sikt/sds-toggle'

import { Flex } from '../Flex/Flex'
import { getTheme, setTheme } from '@/lib/setTheme'

export function DarkModeSwitch() {
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [useDarkMode, setUseDarkMode] = useState<boolean>(prefersDarkMode)

  useEffect(() => {
    void getTheme().then((theme) => {
      setUseDarkMode(theme ? theme === 'dark' : prefersDarkMode)
    })
  }, [prefersDarkMode])

  const handleChange = () => {
    void setTheme(!useDarkMode ? 'dark' : 'light')
    setUseDarkMode(!useDarkMode)
  }

  return (
    <Flex alignItems="center" fullWidth gap="medium">
      {useDarkMode ? <MoonStars size={32} aria-hidden /> : <Sun size={32} aria-hidden />}
      <ToggleSwitch checked={useDarkMode} label="Dark mode" labelFirst onChange={handleChange} />
    </Flex>
  )
}
