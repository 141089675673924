'use client'

import { useEffect, useState } from 'react'

import { Button } from '@sikt/sds-button'
// eslint-disable-next-line no-restricted-imports
import { Link as SdsLink, ScreenReaderOnly } from '@sikt/sds-core'
import { CancelIcon, MenuIcon } from '@sikt/sds-icons'
import { usePathname } from 'next/navigation'
import { useTranslations } from 'next-intl'

import { Flex } from '@/components/Flex/Flex'
import { Link } from '@/components/Link'
import { Menu } from '@/features/login/Menu/Menu'
import { SignedInCheck } from '@/features/login/SignedInCheck/SignedInCheck'
import { SignInButton } from '@/features/login/SignInButton/SignInButton'
import { UserInfo } from '@/features/login/UserInfo/UserInfo'

import styles from './header.module.css'

export function Header() {
  const t = useTranslations('login.Header')
  const [menuOpen, setMenuOpen] = useState(false)
  const path = usePathname()

  let darkmode = false

  if (typeof window !== 'undefined') {
    darkmode = localStorage.getItem('darkMode') === 'true' ? true : false
  }

  if (darkmode) document.documentElement.style.setProperty('color-scheme', 'dark')

  useEffect(() => {
    setMenuOpen(false)
  }, [path])

  return (
    <header className={styles.headerWrapper} role="banner">
      <ScreenReaderOnly isFocusable className="sds-header__skip-link">
        <SdsLink href="#main">{t('skipLink')}</SdsLink>
      </ScreenReaderOnly>

      <Flex className={styles.header} justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <div className={styles.menu}>
            <Button
              variant="transparent"
              iconVariant="left"
              icon={menuOpen ? <CancelIcon /> : <MenuIcon />}
              onClick={() => {
                setMenuOpen((prev) => !prev)
              }}
              className={styles.menu}
            >
              <span className={styles.fixedWidthText}>{menuOpen ? t('close') : t('meny')}</span>
            </Button>
          </div>

          {menuOpen && (
            <>
              <div
                className={styles.overlay}
                role="none"
                onClick={() => {
                  setMenuOpen(false)
                }}
              />
              <nav aria-label={t('menuDropdown')} role="navigation" className={styles.dropdownMenu}>
                <Menu />
              </nav>
            </>
          )}

          <span className={styles.divider} />

          <Link aria-current="page" href="/" className={styles.logo}>
            {t('logo')}
          </Link>
        </Flex>

        <SignedInCheck placeholder={<SignInButton />}>
          <UserInfo />
        </SignedInCheck>
      </Flex>
    </header>
  )
}
