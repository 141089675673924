'use client'
import { useEffect, useRef } from 'react'

import { registerInstrumentationClient } from '@/instrumentation.client'

export function InstrumentationClient() {
  const cleanupRef = useRef<() => void>()

  useEffect(() => {
    const registerClient = async () => {
      const unloadRegisterInstrumentation = await registerInstrumentationClient()
      cleanupRef.current = unloadRegisterInstrumentation
    }
    void registerClient()
    return () => {
      if (cleanupRef.current) {
        cleanupRef.current()
      }
    }
  }, [])

  return null
}
