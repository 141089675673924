import { type ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { getSessionTokenCookieName } from './auth/getSessionTokenCookieName'
import { sudoHeadersByKey } from '@/app/api/graphql/types'

interface GetAuthLinkProps {
  sessionTokenValue: string | undefined
  fnr: string | undefined
  orgnr: string | undefined
}

export const getAuthLink = ({
  sessionTokenValue,
  fnr,
  orgnr,
}: GetAuthLinkProps): ApolloLink | undefined =>
  sessionTokenValue
    ? setContext(() => ({
        headers: {
          Cookie:
            typeof document === 'undefined'
              ? `${getSessionTokenCookieName()}=${sessionTokenValue}`
              : document.cookie,
          [sudoHeadersByKey.Fnr]: fnr,
          [sudoHeadersByKey.Orgnr]: orgnr,
        },
      }))
    : undefined
