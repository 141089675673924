'use client'

import { type ReactNode } from 'react'

import { BreadcrumbItem } from '@sikt/sds-breadcrumbs'
import { Heading1 } from '@sikt/sds-core'

import { Link } from '../Link'
import { useDynamicBreadcrumbs } from './useDynamicBreadcrumbs'
import { Breadcrumbs } from '@/components/Breadcrumbs/Breadcrumbs'
import { type RouteHref } from '@/types/routes'

import styles from './PageLayout.module.css'

interface PageLayoutProps {
  children?: ReactNode
}

export function PageLayout({ children }: PageLayoutProps) {
  //const t = useTranslations('components.PageLayout')
  const { breadcrumbs } = useDynamicBreadcrumbs()
  const sortedBreadcrumbsByHrefLength = [...breadcrumbs].sort(
    (a, b) => a.href.length - b.href.length,
  )
  const title = sortedBreadcrumbsByHrefLength.at(-1)?.title
  const hasBreadcrumbs = sortedBreadcrumbsByHrefLength.length > 0

  return (
    <div className={styles.container}>
      <main id="main" className={styles.content}>
        {title && <Heading1 variant="medium">{title}</Heading1>}

        {hasBreadcrumbs && (
          <Breadcrumbs>
            {sortedBreadcrumbsByHrefLength.map((breadcrumb, index) => (
              <BreadcrumbItem key={breadcrumb.key}>
                {index === breadcrumbs.length - 1 ? (
                  <span>{decodeURIComponent(breadcrumb.label)}</span>
                ) : (
                  <Link
                    href={breadcrumb.href as RouteHref}
                    aria-current={!children ? 'page' : undefined}
                  >
                    {breadcrumb.label}
                  </Link>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumbs>
        )}

        {children}
      </main>
    </div>
  )
}
