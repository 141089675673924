import(/* webpackMode: "eager" */ "/builds/studieadm/fs-admin/node_modules/@sikt/sds-logo/Favicon-Light.svg");
;
import(/* webpackMode: "eager" */ "/builds/studieadm/fs-admin/node_modules/@sikt/sds-logo/Favicon-Light@180px.png");
;
import(/* webpackMode: "eager" */ "/builds/studieadm/fs-admin/node_modules/@sikt/sds-logo/Favicon-Light@32px.png");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/builds/studieadm/fs-admin/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/studieadm/fs-admin/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/builds/studieadm/fs-admin/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/builds/studieadm/fs-admin/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/builds/studieadm/fs-admin/src/app/layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/builds/studieadm/fs-admin/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InstrumentationClient"] */ "/builds/studieadm/fs-admin/src/components/InstrumentationClient/InstrumentationClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageLayout"] */ "/builds/studieadm/fs-admin/src/components/PageLayout/PageLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Snackbar"] */ "/builds/studieadm/fs-admin/src/components/Snackbar/Snackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/builds/studieadm/fs-admin/src/features/login/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionTimeout"] */ "/builds/studieadm/fs-admin/src/features/login/SessionTimeout/SessionTimeout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CommandPalette"] */ "/builds/studieadm/fs-admin/src/features/search/CommandPalette/CommandPalette.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/builds/studieadm/fs-admin/src/lib/apollo/ApolloWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserContextProvider"] */ "/builds/studieadm/fs-admin/src/lib/auth/userContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationEvents"] */ "/builds/studieadm/fs-admin/src/lib/matomo/NavigationEvents/NavigationEvents.tsx");
