import { useMemo } from 'react'

import { SeverityNumber } from '@opentelemetry/api-logs'

import { loggAction } from './loggerAction'
import { useEnvironment } from '@/hooks/useEnvironment'

export const useLogger = () => {
  const { environment } = useEnvironment()

  return useMemo(
    () => ({
      debug: (body: string) => {
        if (environment !== 'production') {
          void loggAction({
            body,
            severityNumber: SeverityNumber.DEBUG,
          })
        }
      },
      info: (body: string) => {
        if (environment !== 'production') {
          void loggAction({
            body,
            severityNumber: SeverityNumber.INFO,
          })
        }
      },
      warn: (body: string) => {
        void loggAction({
          body,
          severityNumber: SeverityNumber.WARN,
        })
      },
      error: (body: string) => {
        void loggAction({
          body,
          severityNumber: SeverityNumber.ERROR,
        })
      },
    }),
    [environment],
  )
}
