'use client'

import { createContext, Suspense, useCallback, useContext, useMemo, useState } from 'react'

import { setUserCookie } from './userCookies'

export interface UserInformation {
  fornavn: string
  etternavn: string
  beskrivelse: string
  identifikator: string
  rollekode: string
  navn: string
  organisasjonsnummer: string
}

interface UserContextProps {
  userInfo: UserInformation | undefined
  setUserInformation: (user: UserInformation | undefined) => void
}

const UserContext = createContext<UserContextProps>({
  userInfo: undefined,
  setUserInformation: () => null,
})

export const useUserContext = () => useContext(UserContext)

interface UserContextProviderProps {
  initUser: UserInformation | undefined
  children: React.ReactNode
}

export function UserContextProvider({ initUser, children }: UserContextProviderProps) {
  const [userInfo, setUserInfo] = useState(initUser)

  const setUserInformation = useCallback((user: UserInformation | undefined) => {
    setUserInfo(user)
    setUserCookie(user)
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const contextValue = useMemo(
    () => ({
      userInfo,
      setUserInformation,
    }),
    [userInfo, setUserInformation],
  )

  return (
    <UserContext.Provider value={contextValue}>
      <Suspense>{children}</Suspense>
    </UserContext.Provider>
  )
}
