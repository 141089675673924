/* eslint-disable no-restricted-imports */
import { Link as SdsLink, type LinkProps as SdsLinkProps } from '@sikt/sds-core'
import { type LinkProps } from 'next/link'

import { TypedLinkWrapper } from '@/components/TypedLinkWrapper/TypedLinkWrapper'
import { type RouteHref } from '@/types/routes'

interface ButtonLinkProps extends Omit<SdsLinkProps, 'href'> {
  nextLinkProps?: Omit<LinkProps, 'href'>
  href: RouteHref
}

export const Link = ({ href, children, nextLinkProps, ...rest }: ButtonLinkProps) => {
  return (
    <TypedLinkWrapper passHref legacyBehavior href={href} {...nextLinkProps}>
      <SdsLink {...rest}>{children}</SdsLink>
    </TypedLinkWrapper>
  )
}
