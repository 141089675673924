'use client'

import { useEffect, useRef, useState } from 'react'

import { Dialog } from '@sikt/sds-dialog'
import { usePathname } from 'next/navigation'
import { signOut, useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'

import { SignInButton } from '@/features/login/SignInButton/SignInButton'
import { SignOutButton } from '@/features/login/Signoutbutton/SignOutButton'

const MINUTE_IN_MS = 60 * 1000
const CHECK_EXPIRED_INTERVAL_MINUTES = 5
const WARN_IF_EXPIRES_IN_MINUTES = 10

export function SessionTimeout() {
  const t = useTranslations('login.SessionTimeout')
  const [showModal, setShowModal] = useState(false)
  const { data: session } = useSession()
  const pathname = usePathname()
  const interval = useRef<ReturnType<typeof setInterval> | null>(null)

  const sessionExpires = session?.feideTokenExpires

  useEffect(() => {
    const callback = () => {
      const isLoggedIn = sessionExpires !== undefined

      if (isLoggedIn) {
        const now = Date.now()
        const expires = new Date(sessionExpires).getTime()
        const isSessionExpired = expires < now
        const warningTimeMs = WARN_IF_EXPIRES_IN_MINUTES * MINUTE_IN_MS
        const isSessionAboutToExpire = expires - warningTimeMs < now

        if (isSessionExpired) {
          if (interval.current) {
            clearInterval(interval.current)
          }
          signOut({
            callbackUrl: pathname,
          }).catch(() => {
            console.error('Session timeout: Failed to sign out user')
          })
        } else if (isSessionAboutToExpire) {
          setShowModal(true)
        }
      } else if (interval.current) {
        clearInterval(interval.current)
      }
    }

    callback()

    interval.current = setInterval(callback, CHECK_EXPIRED_INTERVAL_MINUTES * MINUTE_IN_MS)

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [sessionExpires, pathname])

  return (
    <Dialog
      heading={t('heading')}
      footer={
        <>
          <SignOutButton />
          <SignInButton callbackUrl={pathname} />
        </>
      }
      onClose={() => {
        setShowModal(false)
      }}
      closeButtonLabel={t('close')}
      open={showModal}
    >
      {t('body', { tid: WARN_IF_EXPIRES_IN_MINUTES })}
    </Dialog>
  )
}
