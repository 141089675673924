'use client'

import { type ReactNode } from 'react'

import { useSession } from 'next-auth/react'

interface SignedInCheckProps {
  children?: ReactNode
  placeholder?: ReactNode
}

export function SignedInCheck({ children, placeholder }: SignedInCheckProps) {
  const { status } = useSession()

  if (status !== 'unauthenticated') return children

  // Returning a span to avoid layout shifts if a placeholder is not provided
  return placeholder ?? <span />
}
