'use client'

import { ApolloLink, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import {
  ApolloClient,
  ApolloNextAppProvider,
  InMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support'
import { SeverityNumber, type AnyValueMap } from '@opentelemetry/api-logs'

import { cacheConfig } from './cacheConfig'
import { getAuthLink } from '../getAuthLink'
import { type WithChildren } from '@/types/genericReactProps'
import { loggAction } from '@/utils/logger/loggerAction'

interface MakeClientProps {
  sessionTokenValue: string | undefined
  sudoFnr: string | undefined
  sudoOrgnr: string | undefined
  appBaseUrl: string | undefined
}

function makeClient({ sessionTokenValue, sudoFnr, sudoOrgnr, appBaseUrl }: MakeClientProps) {
  const authLink = getAuthLink({ sessionTokenValue, fnr: sudoFnr, orgnr: sudoOrgnr })
  const httpLink = new HttpLink({
    uri: `${appBaseUrl ?? ''}/api/graphql`,
  })

  const httpLinkWithAuth = authLink ? authLink.concat(httpLink) : httpLink

  return new ApolloClient({
    dataMasking: true,
    cache: new InMemoryCache(cacheConfig),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            // onError(({ graphQLErrors, networkError }) => {
            //   if (graphQLErrors)
            //     graphQLErrors.map(({ message, locations, path }) => {
            //       console.log(
            //         `[GraphQL error]: Message: ${message}, Location: ${locations ? JSON.stringify(locations) : 'N/A'} Path: ${path?.join(',')}`,
            //       )
            //     })
            //   if (networkError) console.log(`[Network error]: ${networkError.toString()}`)
            // }),
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLinkWithAuth,
          ])
        : ApolloLink.from([
            onError(({ graphQLErrors, networkError, operation }) => {
              if (graphQLErrors)
                graphQLErrors.map(({ message, locations, path }) => {
                  console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations ? JSON.stringify(locations) : 'N/A'} Path: ${path?.join(',')}, Operation: ${operation.operationName}`,
                  )
                  const attributes: AnyValueMap = {}

                  if (locations) {
                    attributes.locations = JSON.stringify(locations)
                  }

                  if (path) {
                    attributes.path = path.join(',')
                  }

                  attributes.operationName = operation.operationName

                  void loggAction({
                    body: `[GraphQL error]: Message: ${message}, Location: ${locations ? JSON.stringify(locations) : 'N/A'} Path: ${path?.join(',')}, Operation: ${operation.operationName}`,
                    severityNumber: SeverityNumber.ERROR,
                    attributes,
                  })
                })
              if (networkError) console.log(`[Network error]: ${networkError.toString()}`)
            }),
            httpLinkWithAuth,
          ]),
    credentials: 'same-origin',
  })
}

interface ApolloWrapperProps extends WithChildren {
  sessionTokenValue: string | undefined
  sudoFnr: string | undefined
  sudoOrgnr: string | undefined
}

export function ApolloWrapper({
  children,
  sessionTokenValue,
  sudoFnr,
  sudoOrgnr,
}: ApolloWrapperProps) {
  const appBaseUrl = process.env.NEXT_PUBLIC_APP_BASE_URL

  return (
    <ApolloNextAppProvider
      makeClient={() =>
        makeClient({
          sessionTokenValue,
          sudoFnr,
          sudoOrgnr,
          appBaseUrl: appBaseUrl ?? undefined,
        })
      }
    >
      {children}
    </ApolloNextAppProvider>
  )
}
