'use client'

import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { Resource } from '@opentelemetry/resources'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { StackContextManager, WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions'

import { getEnvironmentVariablesAction } from './utils/environmentVariables/getEnvironmentVariablesAction'

export async function registerInstrumentationClient(): Promise<() => void> {
  const ENVIRONMENT_VARIABLES = await getEnvironmentVariablesAction()
  const { OTEL_ENVIRONMENT, NEXT_PUBLIC_APP_BASE_URL } = ENVIRONMENT_VARIABLES
  const resource = Resource.default().merge(
    new Resource({
      [ATTR_SERVICE_NAME]: 'fs-admin-client',
      ['deployment.environment']: OTEL_ENVIRONMENT,
    }),
  )

  const provider = new WebTracerProvider({
    resource,
    spanProcessors: [
      // new BatchSpanProcessor(new ConsoleSpanExporter()),
      new BatchSpanProcessor(
        new OTLPTraceExporter({
          url: `${NEXT_PUBLIC_APP_BASE_URL}/api/collector`,
        }),
      ),
    ],
  })

  // ZoneContextManager is not compatible with current es transpiled version
  provider.register({
    // contextManager: new ZoneContextManager(),
    contextManager: new StackContextManager(),
  })

  // Registering instrumentations
  const unloadRegisterInstrumentations = registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-xml-http-request': {
          clearTimingResources: true,
        },
      }),
    ],
  })

  return unloadRegisterInstrumentations
}
