'use client'

import { useState } from 'react'

import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'

import { MenuItem, type MenuItemType } from './MenuItem/MenuItem'
import { WithAuth } from '../Authentication/WithAuth'
import { DarkModeSwitch } from '@/components/DarkModeSwitch/DarkModeSwitch'
import { Divider } from '@/components/Divider/Divider'
import { Flex } from '@/components/Flex/Flex'
import { Personas } from '@/features/login/Personas/Personas'
import { SignedInCheck } from '@/features/login/SignedInCheck/SignedInCheck'
import { SignInButton } from '@/features/login/SignInButton/SignInButton'
import { SignOutButton } from '@/features/login/Signoutbutton/SignOutButton'
import { ConditionalWrapper } from '@/features/personer/GroupSearch/utils/ConditionalWrapper'
import { USER_ACTION } from '@/types/userActions'
import { FeatureFlag } from '@/utils/featureFlags/FeatureFlag'
import { useFeatureFlagFetcher } from '@/utils/featureFlags/useFeatureFlagFetcher'

export function Menu() {
  const t = useTranslations('login.Menu')
  const { status } = useSession()
  const [expandedMenuItems, setExpandedMenuItems] = useState<Record<string, boolean>>({})

  useFeatureFlagFetcher()

  const menuItems: MenuItemType[] = [
    { href: '/', title: t('home') },
    {
      href: '/opptak',
      title: t('opptak'),
      featureFlag: {
        flag: 'opptak-menu-parent',
        environmentsOverride: {
          inDevelopment: true,
          inReview: true,
          inTest: true,
        },
      },
      userActions: [USER_ACTION.MODIFISERE_OPPTAK, USER_ACTION.SE_OPPTAK],
    },
    {
      href: '/regelverk',
      title: t('regelverk'),
      featureFlag: {
        flag: 'regelverk',
        environmentsOverride: {
          inDevelopment: true,
          inReview: true,
          inTest: true,
        },
      },
      userActions: [USER_ACTION.SE_REGELVERK, USER_ACTION.MODIFISERE_REGELVERK],
    },
    {
      href: '/soknadsbehandling',
      title: t('soknadsbehandling'),
      featureFlag: {
        flag: 'soknadsbehandling',
        environmentsOverride: {
          inDevelopment: true,
          inReview: true,
          inTest: true,
        },
      },
      userActions: [USER_ACTION.SE_SOKNADSBEHANDLING, USER_ACTION.MODIFISERE_SOKNADSBEHANDLING],
    },
    {
      href: '/person/personsok',
      title: t('personer'),
    },
    {
      href: '/person/gruppesok',
      title: t('groupSearch'),
    },
  ]

  const toggleMenu = (title: string) => {
    setExpandedMenuItems((prev) => ({
      ...prev,
      [title]: !prev[title],
    }))
  }

  return (
    <>
      <SignedInCheck>
        <Flex direction="column">
          {menuItems.map((item) => (
            <ConditionalWrapper
              key={item.title}
              condition={Boolean(item.featureFlag)}
              wrapper={(children: JSX.Element) => (
                <FeatureFlag
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  flag={item.featureFlag!.flag}
                  environmentsOverride={item.featureFlag?.environmentsOverride}
                  doNotFetchFlags
                >
                  {children}
                </FeatureFlag>
              )}
            >
              <ConditionalWrapper
                condition={Boolean((item.userActions?.length ?? 0) > 0)}
                wrapper={(children: JSX.Element) => (
                  <WithAuth permittedRoles={item.userActions ?? []}>{children}</WithAuth>
                )}
              >
                <MenuItem
                  key={item.title}
                  icon={undefined}
                  href={item.href}
                  expandable={!!item.children?.length}
                  isExpanded={expandedMenuItems[item.title]}
                  onToggleExpand={() => {
                    toggleMenu(item.title)
                  }}
                  subMenuItems={item.children}
                  expandedMenus={expandedMenuItems}
                  toggleMenu={toggleMenu}
                >
                  {item.title}
                </MenuItem>
              </ConditionalWrapper>
            </ConditionalWrapper>
          ))}
          <Divider />
        </Flex>
      </SignedInCheck>
      <Flex direction="column" gap="medium">
        <DarkModeSwitch />
        <SignedInCheck>
          <FeatureFlag
            flag="personas"
            environmentsOverride={{
              inDevelopment: true,
              inReview: true,
              inTest: true,
            }}
            doNotFetchFlags
          >
            <Personas />
          </FeatureFlag>
        </SignedInCheck>
        {status === 'authenticated' ? <SignOutButton /> : <SignInButton />}
      </Flex>
    </>
  )
}
