'use client'

import { type ReactNode } from 'react'

import { useTypedRouter } from '@/hooks/useTypedRouter/useTypedRouter'
import { useAdmissioUserActions } from '@/lib/auth/useAdmissioUserActions'
import { type USER_ACTION } from '@/types/userActions'

export interface WithAuthProps {
  permittedRoles?: USER_ACTION[]
  children: ReactNode
  placeholder?: ReactNode
  redirect?: boolean
}

export function WithAuth({
  permittedRoles,
  children,
  placeholder,
  redirect = false,
}: WithAuthProps) {
  const { userActions, loading } = useAdmissioUserActions()
  const router = useTypedRouter()

  if (permittedRoles && !loading) {
    if (permittedRoles.some((role) => userActions.includes(role))) {
      return children
    } else {
      if (placeholder) {
        return placeholder
      }
      if (redirect) {
        router.replace('/unauthorized')
      }
      return null
    }
  }
}
