// eslint-disable-next-line no-restricted-imports
import NextLink, { type LinkProps } from 'next/link'

import { type RouteHref } from '@/types/routes'
import { getPathString } from '@/utils/routeUtils'

interface TypedLinkProps {
  children: React.ReactNode
  href: RouteHref
  nextLinkProps?: Omit<LinkProps, 'href'>
}

export const TypedLinkWrapper = ({ href, children, nextLinkProps, ...rest }: TypedLinkProps) => {
  const path = typeof href === 'string' ? href : getPathString(href)

  return (
    <NextLink href={path} passHref legacyBehavior {...rest} {...nextLinkProps}>
      {children}
    </NextLink>
  )
}
