'use client'
import { useEffect, useState } from 'react'

import clsx from 'clsx'
import { Reorder } from 'framer-motion'
import { useTranslations } from 'next-intl'

import { useSnackbar, type Snackbar } from './useSnackbar'
import { Alert } from '../Alert/Alert'

import styles from './Snackbar.module.css'

export function Snackbar() {
  const { snackbars, closeSnackbar, setSnackbars } = useSnackbar()

  return (
    <div aria-live="assertive">
      <Reorder.Group
        values={snackbars}
        onReorder={setSnackbars}
        className={styles.snackbarContainer}
      >
        {snackbars.map((snackbar) => (
          <SnackbarItem key={snackbar.id} snackbar={snackbar} closeSnackbar={closeSnackbar} />
        ))}
      </Reorder.Group>
    </div>
  )
}

interface SnackbarItemProps {
  snackbar: Snackbar
  closeSnackbar: (id: string) => void
}

const SnackbarItem = ({ snackbar, closeSnackbar }: SnackbarItemProps) => {
  const t = useTranslations('common')
  const [isRemoving, setIsRemoving] = useState(false)

  useEffect(() => {
    let timer: number
    if (snackbar.duration && snackbar.duration > 0) {
      timer = window.setTimeout(() => {
        handleClose()
      }, snackbar.duration)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackbar])

  const handleClose = () => {
    // Trigger removal animation
    setIsRemoving(true)
    // Wait for animation to finish before closing
    window.setTimeout(() => {
      closeSnackbar(snackbar.id)
    }, 300) // Duration should match your CSS animation duration
  }

  const handleAction = () => {
    if ('actionFunction' in snackbar && snackbar.actionFunction) {
      snackbar.actionFunction(() => {
        closeSnackbar(snackbar.id)
      })
    }
  }

  return (
    <Reorder.Item value={snackbar} dragListener={false} className={styles['list-item']}>
      <div className={clsx(styles.slidein, isRemoving && styles.slideout)}>
        <Alert
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={handleClose}
          closeButtonLabel={t('close')}
          onAction={handleAction}
          actionButtonLabel={snackbar.actionFunctionLabel ?? ''}
        />
      </div>
    </Reorder.Item>
  )
}
