'use client'

import { useEffect, useMemo } from 'react'

import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { getEnvironmentAction } from '@/utils/environment/getEnvironmentAction'
import { type Environment } from '@/utils/environment/getEnvironmentAction.types'

interface UseEnvironmentBase {
  environment: Environment | null
}

interface UseEnvironmentActions {
  fetchEnvironment: () => Promise<void>
}

const useEnvironmentStore = create(
  immer<UseEnvironmentBase & UseEnvironmentActions>((set, get) => ({
    environment: null,
    fetchEnvironment: async () => {
      const env = get().environment
      if (env) return
      const res = await getEnvironmentAction()
      set((state: UseEnvironmentBase) => {
        state.environment = res.environment
      })
    },
  })),
)

export function useEnvironment() {
  const { environment, fetchEnvironment } = useEnvironmentStore()

  useEffect(() => {
    void fetchEnvironment()
  }, [fetchEnvironment])

  return useMemo(() => ({ environment }), [environment])
}
