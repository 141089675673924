export enum USER_ACTION {
  SE_UTDANNING = 'SE_UTDANNING',
  SE_SOKNADSBEHANDLING = 'SE_SØKNADSBEHANDLING',
  SE_SOKNAD = 'SE_SØKNAD',
  SE_REGELVERK = 'SE_REGELVERK',
  SE_ORGANISASJONER = 'SE_ORGANISASJONER',
  SE_OPPTAK = 'SE_OPPTAK',
  MODIFISERE_UTDANNING = 'MODIFISERE_UTDANNING',
  MODIFISERE_SØKNAD = 'MODIFISERE_SØKNAD',
  MODIFISERE_SOKNADSBEHANDLING = 'MODIFISERE_SØKNADSBEHANDLING',
  MODIFISERE_REGELVERK = 'MODIFISERE_REGELVERK',
  MODIFISERE_ORGANISASJONER = 'MODIFISERE_ORGANISASJONER',
  MODIFISERE_OPPTAK = 'MODIFISERE_OPPTAK',
}
