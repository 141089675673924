'use client'

import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { gql } from '@/__generated__'

export const GET_USER_QUERY = gql(/* GraphQL */ `
  query getUser {
    minBruker {
      handlinger
      id
    }
  }
`)

interface UseAdmissioUserActionsData {
  userActions: string[]
  userId: string
  error: Error | undefined
  loading: boolean
}

export function useAdmissioUserActions(): UseAdmissioUserActionsData {
  const { data, error, loading } = useQuery(GET_USER_QUERY)

  return useMemo(
    () => ({
      userActions: data?.minBruker?.handlinger ?? [],
      userId: data?.minBruker?.id ?? '',
      error,
      loading,
    }),
    [data?.minBruker?.handlinger, data?.minBruker?.id, error, loading],
  )
}
