export const isMacOS = () => {
  const userAgentData =
    'userAgentData' in navigator &&
    typeof navigator.userAgentData == 'object' &&
    navigator.userAgentData

  const userAgentPlatform =
    userAgentData &&
    'platform' in userAgentData &&
    typeof userAgentData.platform === 'string' &&
    userAgentData.platform

  return userAgentPlatform
    ? userAgentPlatform === 'macOS'
    : /Macintosh|Mac OS X/.test(navigator.userAgent)
}
