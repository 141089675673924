'use client'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { TextInput } from '@sikt/sds-input'
import Fuse, { type FuseResult } from 'fuse.js'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'

import { useCommands, type Command } from './hooks/useCommands'
import { Flex } from '@/components/Flex/Flex'
import { Surface } from '@/components/Surface/Surface'
import { CommandGroupComponent } from '@/features/search/CommandPalette/CommandGroupComponent/CommandGroupComponent'
import { useFeatureFlagFetcher } from '@/utils/featureFlags/useFeatureFlagFetcher'
import { flagsStore } from '@/utils/featureFlags/useTypedFlag'
import { useLogger } from '@/utils/logger/useLogger'

import styles from './CommandPalette.module.css'

export function CommandPalette() {
  const t = useTranslations('search.CommandPalette')
  const { status } = useSession()
  const authenticated = status === 'authenticated'
  const [isOpen, setIsOpen] = useState(false)
  const [filterValue, setFilterValue] = useState('')

  useFeatureFlagFetcher()
  const { flags } = flagsStore()
  const { info } = useLogger()

  const { commandGroups } = useCommands()

  // log error if duplicate shortcuts are found
  const shortcuts = commandGroups.flatMap((group) =>
    group.commands.map((command) => command.shortcut).filter(Boolean),
  )
  if (new Set(shortcuts).size !== shortcuts.length) {
    console.error('Duplicate shortcuts found')
  }

  const ref = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (isOpen && ref.current) {
      ref.current.showModal()
    } else if (!isOpen && ref.current) {
      ref.current.close()
    }
  }, [isOpen])

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setIsOpen(true)
        info('Opened command palette')
      }
    }

    const onMouseClick = (e: MouseEvent) => {
      if (isOpen && e.target === ref.current) {
        setIsOpen(false)
      }
    }

    if (authenticated) {
      window.addEventListener('click', onMouseClick)
      window.addEventListener('keydown', onKeydown)
    }

    return () => {
      window.removeEventListener('click', onMouseClick)
      window.removeEventListener('keydown', onKeydown)
    }
  }, [authenticated, ref, isOpen, info])

  const handleClosePalette = useCallback(() => {
    setIsOpen(false)
    setFilterValue('')
  }, [])

  const filteredCommands = useMemo(
    () =>
      commandGroups
        .flatMap((group) => group.commands)
        .filter((command) => {
          if (flags && command.featureFlag) {
            return flags.find((flag) => flag.name === command.featureFlag?.flag)?.enabled
          } else {
            return true
          }
        }),
    [commandGroups, flags],
  )

  const fuse = useMemo(
    () =>
      new Fuse(filteredCommands, {
        includeScore: true,
        keys: ['name'],
        findAllMatches: true,
        includeMatches: true,
      }),
    [filteredCommands],
  )

  const filteredCommandGroups = useMemo(
    () =>
      !filterValue
        ? (() => {
            // Create new groups with filtered commands and exclude groups without commands
            const newCommandGroups = commandGroups
              .map((group) => ({
                ...group,
                commands: group.commands.filter((command) =>
                  filteredCommands.find((c) => c.id === command.id),
                ),
              }))
              .filter((group) => group.commands.length > 0)
            return newCommandGroups
          })()
        : (() => {
            // Perform the search
            const searchResults: FuseResult<Command>[] = fuse.search(filterValue)
            const commandSearchResults = searchResults.map((result) => result.item)

            // Create a set of IDs from search results to avoid duplicates
            const searchResultIds = new Set(commandSearchResults.map((command) => command.id))

            const alwaysIncludeCommands = filteredCommands.filter((command) => command.alwaysShow)

            // Filter the always-included commands to exclude any already in search results
            const additionalCommands = alwaysIncludeCommands.filter(
              (command) => !searchResultIds.has(command.id),
            )

            // Combine search results with the additional commands, return only 10 results
            const combinedSearchResults = [...commandSearchResults, ...additionalCommands]

            // Create new groups with filtered commands and exclude groups without commands
            const newCommandGroups = commandGroups
              .map((group) => ({
                ...group,
                commands: group.commands.filter((command) =>
                  combinedSearchResults.includes(command),
                ),
              }))
              .filter((group) => group.commands.length > 0)

            // Add FuseResult to commands
            const commandGroupsWithFuseResult = newCommandGroups.map((group) => ({
              ...group,
              commands: group.commands.map((command) => ({
                ...command,
                fuseResult: searchResults.find((result) => result.item === command),
              })),
            }))

            // sort all commands within groups, by the best match
            commandGroupsWithFuseResult.forEach((group) => {
              group.commands.sort((a, b) => {
                if (a.fuseResult?.score && b.fuseResult?.score) {
                  return a.fuseResult.score - b.fuseResult.score
                }
                return 0
              })
            })

            // sort groups by the best first match within the group
            commandGroupsWithFuseResult.sort((a, b) => {
              const scoreA = a.commands[0]?.fuseResult?.score ?? Number.MAX_SAFE_INTEGER
              const scoreB = b.commands[0]?.fuseResult?.score ?? Number.MAX_SAFE_INTEGER
              return scoreA - scoreB
            })

            return commandGroupsWithFuseResult
          })(),
    [commandGroups, filterValue, filteredCommands, fuse],
  )

  return (
    <dialog
      ref={ref}
      onCancel={handleClosePalette}
      aria-label={t('dialogAriaLabel')}
      className={styles.dialog}
    >
      <Surface padding="minimal">
        <Flex direction="column" gap="small">
          <Surface padding="tiny">
            <TextInput
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value)
              }}
              label=""
              aria-label={t('inputAriaLabel')}
              className={styles.input}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              autoComplete="off"
            />
          </Surface>
          <Surface padding="tiny" classname={styles.results}>
            <Flex direction="column" gap="small">
              {isOpen &&
                filteredCommandGroups.map((group) => (
                  <CommandGroupComponent
                    key={group.id}
                    group={group.heading}
                    commandGroup={group}
                    filterValue={filterValue}
                    onClosePalette={handleClosePalette}
                  />
                ))}
            </Flex>
          </Surface>
        </Flex>
      </Surface>
    </dialog>
  )
}
