'use client'

import { Button, type ButtonProps } from '@sikt/sds-button'
import { SignInIcon } from '@sikt/sds-icons'
import { useSearchParams } from 'next/navigation'
import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'

export const SignInButton = ({
  variant,
  shortLabel = false,
  callbackUrl,
  ...rest
}: Omit<ButtonProps, 'children'> & {
  callbackUrl?: string
  shortLabel?: boolean
}) => {
  const t = useTranslations('login.SignInButton')
  const searchParams = useSearchParams()
  const signInCallbackUrl = callbackUrl ?? searchParams.get('signInCallbackUrl') ?? undefined
  const isValidCallbackUrl = signInCallbackUrl?.startsWith('/') === true

  return (
    <Button
      onClick={() =>
        void signIn('feide', {
          callbackUrl: isValidCallbackUrl ? signInCallbackUrl : '/',
        })
      }
      icon={<SignInIcon />}
      variant={variant ?? 'strong'}
      aria-label={t('loginWithFeide')}
      {...rest}
    >
      {shortLabel ? t('login') : t('loginWithFeide')}
    </Button>
  )
}
