import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

interface Breadcrumb {
  key: string
  label: string
  href: string
  title?: string
}

interface BaseBreadcrumbs {
  breadcrumbs: Breadcrumb[]
}

interface BreadcrumbActions {
  addBreadcrumb: (breadcrumb: Breadcrumb) => void
  removeBreadcrumb: (key: string) => void
}

export const useDynamicBreadcrumbs = create(
  immer<BaseBreadcrumbs & BreadcrumbActions>((set) => ({
    breadcrumbs: [],
    addBreadcrumb: (newBreadcrumb) => {
      set((state: BaseBreadcrumbs) => {
        const breadcrumb = state.breadcrumbs.findIndex((b) => b.key === newBreadcrumb.key)
        if (breadcrumb !== -1) {
          state.breadcrumbs[breadcrumb] = newBreadcrumb
          return
        }
        state.breadcrumbs.push(newBreadcrumb)
      })
    },
    removeBreadcrumb: (key: string) => {
      set((state: BaseBreadcrumbs) => {
        state.breadcrumbs = state.breadcrumbs.filter((breadcrumb) => breadcrumb.key !== key)
      })
    },
  })),
)
